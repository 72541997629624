$bg_black_default: #1A1A1A;
$yellow: #F1C131;
$orange: #E69138;
$purple: #664EA7;
$green: #45818E;
$gray: #747474;
$bg_black_secondary: #707070;

$fontsize_thin : 100; // Thin / Hairline
$fontsize_extra_light : 200; // Ultra-light / Extra-light
$fontsize_light : 300;
$fontsize_normal : 400; // Normal / regular
$fontsize_medium : 500;
$fontsize_semi_bold : 600; // Semi-bold / Demi-bold
$fontsize_bold : 700;
$fontsize_extra_bold : 800; // Extra-bold / Ultra-bold
$fontsize_heavy : 900;

// https://getbootstrap.com/docs/5.2/customize/sass/#variable-defaults
// https://www.freecodecamp.org/news/how-to-customize-bootstrap-with-sass/
$primary: $orange;
$secondary: $yellow;

$input-font-family: 'Roboto', sans-serif !default;
$input-bg: $bg_black_default !default;
$input-color: white !default;

$form-label-font-size: 12px !default;
$form-label-color: white !default;
$form-label-font-weight: $fontsize_bold !default;