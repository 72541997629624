@import './_variables.scss';

.camelia-button {
  display: block;
  margin: auto;
  border-radius: 8px !important;
  border-style: solid;
}

.camelia-button-filled {
  background-color: $orange;
  border-color: $orange;
}

.camelia-button-outlined {
  background-color: #0000;
  border: 1px $orange;
}

.btn-close{
  filter: invert(1);
}
