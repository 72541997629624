@import './src/assets/scss/variables';
@import './src/assets/scss/button';

.your-cart {
  height: 80vh;
  z-index: 100;
  cursor: pointer;
  display: block;
  float: right;
  position: absolute;
  top: 100px;
  background-color: $bg_black_default;
  width: 100%;
  border-radius: 16px;
  padding-top: 2rem;
}

.content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

form button {
  margin-top: 1rem;
  width: 100%;
}

.camelia-button {
  width: 50%;
  max-width: 10rem;
  height: 2.75rem;
}

.camelia-button.w-100 {
  max-width: 100%;
  font-size: 22px;
  height: 2.75rem;
}

.box-price.your-cart-wrapper {
  display: flex;
  flex-flow: row wrap;
  background-color: $gray;
  min-height: 5rem;
  margin: 2rem 0;
  border-radius: 0.5rem;
  padding: 0 0.5rem !important;
  color: white;

  .icon {
    flex: 1 auto;
    margin-left: -0.5rem;
    text-align: center;

    i {
      font-size: xx-large;
    }
  }

  .title {
    flex: 6 auto;
  }

  .price {
    flex: 3 auto;
    text-align: right;
  }
}

.your-cart-wrapper > * {
  flex: 1 100%;
}

@media (min-width: 481px) {
  .box-price.your-cart-wrapper {
    .icon i {
      font-size: xx-large;
    }

    .title, .price {
      font-size: 18px !important;
      font-weight: $fontsize_medium;
    }
  }
}

@media (max-width: 480px) {
  .box-price.your-cart-wrapper {
    .icon i {
      font-size: x-large;
    }

    .title, .price {
      font-size: 12px !important;
      font-weight: $fontsize_medium;
    }
  }
}

.bg-blue {
  background-color: #4267B2 !important;
  border-color: #4267B2 !important;
}

.payment-method-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow: auto;
}

.credit-card-selected {
  border: 3px $orange solid;
  border-radius: 16px;
  background-color: $orange;
}

.rccs {
  margin: 0 !important;
}

.card-outer {
  margin: 0 auto;
  width: 296px;
  border: 3px transparent solid;
}

.card-rcheckbox {
  position: relative !important;
  left: auto !important;
  bottom: auto !important;
  margin-top: -30px;
  margin-left: 250px;
}


@media (max-width: 350px) {
  .rccs {
    width: 250px !important;
    height: 150px !important;
  }

  .rccs__card {
    width: 250px !important;
    height: 150px !important;
  }

  .card-outer {
    width: 256px;
  }

  .card-rcheckbox {
    margin-top: -30px;
    margin-left: 225px;
  }
}

.form-check-input{
  border-color: white !important;
}