@import "./global.scss";
@import "./_variables.scss";
@import "./session.scss";
@import "./text.scss";
@import "node_modules/bootstrap/scss/bootstrap";

body {
  height: $heightVh100;
  overflow: hidden;
  min-height: -webkit-fill-available;
  * {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
  }
}
html {
  overflow: hidden;
  min-height: -webkit-fill-available;
}
.loading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  cursor: progress;
  background: rgba(0, 0, 0, 0.1);
}
.base-loading {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 99999;
  transform: translate(-50%, -50%);
}
// css margin, padding
// example: m-5px, p-5px
$sizes: 5px, 8px, 10px, 12px, 15px, 16px, 20px, 25px, 30px, 35px;
@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size in $sizes {
    .#{$abbrev}-#{$size} {
      #{$prop}: $size !important;
    }

    .#{$abbrev}l-#{$size} {
      #{$prop}-left: $size !important;
    }

    .#{$abbrev}r-#{$size} {
      #{$prop}-right: $size !important;
    }

    .#{$abbrev}t-#{$size} {
      #{$prop}-top: $size !important;
    }

    .#{$abbrev}b-#{$size} {
      #{$prop}-bottom: $size !important;
    }

    .#{$abbrev}x-#{$size} {
      #{$prop}-left: $size !important;
      #{$prop}-right: $size !important;
    }

    .#{$abbrev}y-#{$size} {
      #{$prop}-top: $size !important;
      #{$prop}-bottom: $size !important;
    }
  }
}
// css font-size
// example: fs-11px
$fontsize: 10px, 11px, 12px, 13px, 14px, 15px, 16px, 17px, 18px, 20px, 22px,
  24px, 25px, 28px, 30px;
@each $fonts in $fontsize {
  .fs-#{$fonts} {
    font-size: $fonts !important;
  }

  // example: fs-11px-b
  .fs-#{$fonts}-b {
    font-size: $fonts !important;
    font-weight: $fontsize_bold;
  }

  // example: fs-11px-m
  .fs-#{$fonts}-m {
    font-size: $fonts !important;
    font-weight: $fontsize_medium;
  }

  // example: fs-11px-r
  .fs-#{$fonts}-r {
    font-size: $fonts !important;
    font-weight: $fontsize_normal;
  }
}
// css with %: example w-5
$sizeWidths: (
  "5": 5%,
  "15": 15%,
  "10": 10%,
  "20": 20%,
  "21": 21%,
  "30": 30%,
  "40": 40%,
  "45": 45%,
  "55": 55%,
  "60": 60%,
  "70": 70%,
  "79": 79%,
  "80": 80%,
  "90": 90%,
);
@each $name, $w in $sizeWidths {
  .w-#{$name} {
    width: $w !important;
  }
}
.content-page {
  position: relative;
  top: 60px;
  height: calc($heightVh100 - 120px);
  .container-page {
    margin: auto;
    max-width: 768px;
    background-color: $bg_black_default;
    height: auto;
    .layout-content {
      background-color: $bg_black_default;
      overflow: auto;
      height: calc($heightVh100 - 120px);
      -webkit-overflow-scrolling: touch;
      position: relative;
      &::-webkit-scrollbar {
        background-color: #f5f5f5;
        width: 8px;
        height: 8px;
        cursor: pointer;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        box-shadow: inset 0 0 6px rgb(0 0 0 / 0%);
        background-color: #ddd;
        cursor: pointer;
      }
    }
  }
}
.wrapper {
  height: $heightVh100;
}
.header {
  height: 60px;
  margin: auto;
  background-color: $bg_black_default;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  user-select: none;
  img {
    max-width: 122px;
  }
  .content-header {
    height: 60px;
    max-width: 1000px;
    margin: auto;
    .box-menu {
      a {
        color: #ffffff;
        text-decoration: none;
        font-weight: 400;
      }
      .box-menu-button {
        border-radius: 16px;
        padding: 4px 20px;
      }
      .box-login {
        background-color: #ffffff;
        div {
          color: #e69138;
        }
      }
      .box-register {
        background-color: #e69138;
      }
    }
  }
  .user-information {
    a {
      text-decoration: none;
    }
    .avatar {
      img {
        height: 40px;
        width: 40px;
        object-fit: cover;
      }
    }
    .information {
      div {
        font-size: 10px;
      }
    }
  }
}

.footer {
  background-color: $bg_black_default;
  height: 60px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
  .layout-footer {
    width: 1000px;
    height: 60px;
  }
  .list-item {
    display: flex;
    height: 60px;
    .box-link {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20%;
      a {
        padding: 10px;
        user-select: none;
        &.active {
          background-color: #fff;
          border-radius: 50%;
          img {
            filter: invert(85%) sepia(9%) saturate(7%) hue-rotate(326deg)
              brightness(96%) contrast(92%);
          }
        }
      }
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}
.app-camelia {
  overflow: hidden;
  height: $heightVh100;
  min-height: -webkit-fill-available;
}
.cs {
  cursor: pointer;
}
.modal {
  overflow: hidden !important;
}
.line {
  width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.line-1 {
  -webkit-line-clamp: 1;
}
.line-2 {
  -webkit-line-clamp: 2;
}
.modal-confirm {
  &.show {
    display: flex !important;
    align-items: center;
    justify-content: center;
    min-height: 400px;
  }
  .modal-dialog {
    width: 400px !important;
  }
  .modal-header {
    background-color: $bg_black_default;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 0;
  }
  .modal-body {
    background-color: $bg_black_default;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 0;
    .action-confirm {
      border-top: 1px solid #212529;
      .box-action {
        padding: 10px;
        color: #fff;
        cursor: pointer;
      }
      .close {
        border-right: 1px solid #212529;
      }
      .submit {
        color: #e69138;
      }
    }
    input {
      background-color: #1a1a1a;
      border: 1px solid #747474;
      color: #fff;
      &:focus {
        box-shadow: unset !important;
        outline: unset !important;
        border: 1px solid #747474;
      }
    }
  }
  .text-description-verify-password {
    color: #707070;
  }
  .body-alert {
    min-height: 80px;
    display: flex;
    align-items: center;
    padding-inline: 16px;
    word-break: keep-all;
  }
}
.content-page-app {
  width: 100%;
  padding: 25px;
  user-select: none;
  .header-common {
    user-select: none;
    height: 66px;
    .title-page {
      font-size: 30px;
    }
    .description-page {
      font-size: 14px;
    }
  }
}
.box-scroll-in-mobile {
  width: 100%;
  height: 120px;
  visibility: hidden;
}
.modal-full-page {
  position: absolute;
  bottom: 0;
  background-color: #1a1a1a;
  width: 100%;
  left: 0;
  opacity: 0;
  transition: 0.4s ease;
  transform: translateY(200%);
  visibility: hidden;
  height: 0;
  padding: -30px;
  overflow: hidden;
  &.show {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    z-index: 99;
    bottom: 0;
    top: 0;
    height: calc($heightVh100 - 120px);
    min-height: -webkit-fill-available;
  }
}
.page-search {
  height: calc($heightVh100 - 120px);
  .box-search {
    margin: 20px 0;
    img {
      width: 18px;
      filter: invert(32%) sepia(3%) saturate(19%) hue-rotate(322deg)
        brightness(103%) contrast(99%);
    }
    .input-group-text {
      background-color: #363637;
      border-color: #363637;
    }
    input {
      height: 38px;
      background-color: #363637;
      border-color: #363637;
      color: #fff;
      &:focus {
        box-shadow: unset !important;
      }
    }
  }
  .list-session-search {
    overflow: auto;
    height: calc($heightVh100 - 290px);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    &::-webkit-scrollbar {
      background-color: #f5f5f5;
      width: 0;
      height: 0;
      cursor: pointer;
    }
  }
}

#preview-image-url {
  width: 100%;
  height: calc($heightVh100 - 180px);
  aspect-ratio: 10/10;
}
@import "./responsive.scss";

.is-ios {
  // .padding-bottom-100 {
  //   padding-bottom: 100px !important;
  // }
  // .padding-bottom-120 {
  //   padding-bottom: 120px !important;
  // }
  // .lb-container {
  //   height: calc($heightVh100 - 185px) !important;
  // }
  #streamer-audience {
    height: calc($heightVh100 - 185px) !important;
  }
}

/* CUSTOM SCROLLBAR */
@media (max-width: 480px) {
  /* hide scrollbar in smart phone mode */
  ::-webkit-scrollbar {
    width: 0 !important;
  }
  .is-ios {
    // .lb-container {
    //   height: calc($heightVh100 - 200px) !important;
    // }
    // #streamer-audience {
    //   height: calc($heightVh100 - 200px) !important;
    // }
  }
}

hr {
  color: white;
}
.modal-content {
  background-color: unset !important;
}
.modal-body {
  &::-webkit-scrollbar {
    background-color: #f5f5f5;
    width: 0;
    height: 0;
    cursor: pointer;
  }
}
small {
  font-size: 10px !important;
}
big {
  font-size: 14px !important;
}
