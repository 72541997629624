@import './_variables.scss';

.roboto {
  font-family: 'Roboto', sans-serif;
}

.yuGothic {
  font-family: 'Yu Gothic', sans-serif;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
