@import "./global.scss";
@import "variables";
.modal-full-page {
    .header-list-image-session {
        height: 60px;
        padding: 0 15px;
        .back {
            min-width: 30px;
            img {
                width: 22px !important;
                filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(71deg) brightness(106%) contrast(101%);
            }
        }
        .title {
            padding: 0 10px;
        }
        .action-page {
            color: #707070;
            min-width: 52px;
            &.enable-button {
                color: #E69138;
                cursor: pointer;
            }
        }
        
    }
    .list-image-session {
        overflow: auto;
        height: calc($heightVh100 - 180px);
        padding: 20px;
        background-color: #1A1A1A;
        .no-data {
            color: #707070;
        }
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
            cursor: pointer;
          }
        &::-webkit-scrollbar-thumb {
            border-radius: 0;
        }
        .box-image {
            width: 25%;
            user-select: none;
            float: left;
            height: 150px;
            .item-box {
                width: 100%;
                position: relative;
                cursor: pointer;
                img {
                    width: 100%;
                    height: 150px;
                    object-fit: cover;
                }
                .checkbox-image {
                    position: absolute;
                    bottom: 5px;
                    left: 5px;
                }
            }
        }
    }
}
.buy_request_status {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    color: white;
    text-align: center;
    background-color: rgba(230, 145, 56, 0.9);
    padding: 6px;
    &.approved {
        background-color: rgba(112, 112, 112, 0.9);
    }
}
.modal-images-session  {
    position: absolute;
    bottom: 0;
    min-height: 250px;
    background-color: #1A1A1A;
    width: 100%;
    left: 0;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    opacity: 0;
	transition: .4s ease;
    transform: translateY(200%);
    visibility: hidden;
	&.show {
		opacity: 1;
        visibility: visible;
        transform: translateY(0);
		z-index: 99;
        height: calc($heightVh100 - 120px);
	}
}
.point_spend_container{
    position: fixed;
    bottom: 30px;
    width: 100%;
    div{
        color: white !important;
        text-align: center;
    }

    .point-needed{
        color: $orange;
    }

    .align-self-center{
        min-width: 100px;
    }
}

@media (max-width: 480px) {
    .modal-images-session  {
        &.show {
            height: calc($heightVh100 - 200px);
            bottom: 0 !important;
        }
    }
    .list-image-session {
        .box-image {
            width: 33.333% !important;
            height: 140px !important;
            img {
                height: 140px !important;
            }
        }
    }
}
@media (max-width: 380px) {
    .modal-images-session  {
        &.show {
            bottom: 0 !important;
        }
    }
}