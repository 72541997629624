.header-feedback {
  height: 24px;
  padding: 0 15px;

  .back {
    min-width: 30px;

    img {
      width: 22px;
      filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(71deg) brightness(106%) contrast(101%);
    }
  }

  .title {
    padding: 0 10px;
    font-size: 14px;
  }

  .action-page {
    color: #707070;
    min-width: 52px;

    &.enable-button {
      color: #E69138;
    }
  }
}

.body-feedback {
  padding: 32px;
  height: 99%;
  color: white;

  @media (max-width: 480px) {
    overflow: scroll;
  }
}

.feedback-content {
  height: 99%;
  //max-height: 50vh;
}

.form-create-feedback {
  height: 99%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    cursor: pointer;
  }
}

.content-area {
  height: 99%;
  //max-height: 48vh;
  //min-height: 60px !important;
}

textarea.form-control, .form-select {
  padding: 0 !important;
  border-color: transparent !important;
  border-width: 0 !important;
  font-size: 12px !important;
}

.text-content {
  font-size: 12px !important;
  font-weight: 500 !important;
  padding: 0 16px;
  //height: 48vh;
}

.camelia-button {
  color: white !important;
}

@media (min-width: 480px) {
  .body-feedback {
    padding: 48px;
  }

  .title {
    font-size: 18px !important;
  }

  textarea.form-control, .form-select, .form-label, .text-content {
    font-size: 16px !important;
  }
}

@media (max-width: 380px) {
  .text-content {
    padding: 0;
  }
}

@media (max-height: 600px) {
  //.feedback-content {
  //  max-height: 200px;
  //}
  //.content-area {
  //  max-height: 180px;
  //}
}