@import "src/assets/scss/variables";

$bg-checkbox: #2C2C2C;
$size-checkbox: 16px;
/* The container */
.rcheckbox-container {
  display: block;
  position: absolute;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-width: $size-checkbox;
  min-height: $size-checkbox;
  left: 0px;
  bottom: 0px;
  width: 32px;
  height: 32px;
  background-color: transparent;
}

/* Hide the browser's default checkbox */
.rcheckbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  bottom: 8px;
  left: 4px;
  height: $size-checkbox;
  width: $size-checkbox;
  background-color: $bg-checkbox;
  border-radius: 32px;
}

/* When the checkbox is checked, add a blue background */
.rcheckbox-container input:checked ~ .checkmark {
  background-color: $orange;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  //display: none;
}

/* Show the checkmark when checked */
.rcheckbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.rcheckbox-container .checkmark:after {
  left: 5px;
  top: 2px;
  width: 6px;
  height: 9px;
  border: solid white;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}