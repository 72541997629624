@import "./global.scss";
.box-next-session {
  .title-box {
    font-size: 16px;
  }

  .countdown-box {
    color: #e69138;
    font-size: 22px;
  }

  .image-box {
    img {
      width: 100%;
      border-radius: 15px;
      height: 380px;
      object-fit: cover;
      background-color: #b2b1b1;
    }
  }

  a {
    text-decoration: none;
  }
}

.title-home {
  font-size: 30px;
  text-transform: uppercase;
  color: #fff;
}

.box-list-session-home {
  .content-session-list {
    flex-wrap: wrap;
    display: flex;
    margin-left: -15px;
    margin-right: -15px;

    .box-session-home {
      width: 50%;
      padding: 15px;

      .item-session {
        .images {
          img {
            height: 125px;
          }
        }

        .info-session {
          width: 90%;
        }
      }
    }

    .box-content {
      color: #fff;
      padding: 10px 0;

      .info-date {
        display: flex;
        flex-wrap: wrap;

        .date {
          padding-right: 10px;
        }
      }
    }
  }

  .action-list-session {
    button {
      color: #fff;
      background-color: #e69138;
      height: 48px;
    }
  }
}

.item-session {
  cursor: pointer;

  a {
    text-decoration: none;
  }

  .images {
    position: relative;
    background-color: #ddd;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    img {
      width: 100%;
      height: 350px;
      object-fit: cover;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }
  }

  .status-session {
    position: absolute;
    bottom: 55px;
    left: 10px;
    padding: 5px 15px;
    border-radius: 6px;
    background: #e69138;
    text-align: center;
    color: #fff;

    &.session-end {
      background: #45818e !important;
    }
  }

  .info-session {
    width: 80%;
    height: 35px;
    display: flex;
    align-items: center;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: absolute;
    bottom: 15px;
    background-color: $bg_black_default;
    left: 0;
    padding: 5px 20px;
    box-shadow: 0 2px 4px #e6913833;

    .date-session {
      flex-wrap: wrap;

      .time {
        padding-left: 10px;
      }
    }
  }

  .capacity-session {
    color: #e69138;
  }

  .title-price-item-session {
    position: relative;
    display: flex;
    height: 58px;
    top: -15px;
    background-color: $bg_black_default;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    box-shadow: 0 2px 4px #e6913833;
    color: #fff;
    align-items: center;
    padding: 0 20px;

    .title-item-session {
      width: 70%;
      font-size: 16px;
      font-weight: 500;
    }

    .price-item-session {
      width: 30%;
      text-align: right;
      font-size: 30px;
      font-weight: 500;

      .name-price {
        position: relative;
        top: 3px;
        left: 3px;
      }
    }
  }
}

.detail-session {
  .image-session-detail {
    position: relative;
    background-color: #ddd;

    img {
      width: 100%;
      object-fit: cover;
      height: 420px;
    }

    .status-session {
      position: absolute;
      bottom: 180px;
      left: 0;
      width: 100%;
      color: #fff;

      .content-text {
        .text {
          background-color: #e69138;
          padding: 3px 12px;
          border-radius: 8px;
          font-size: 12px;

          &.status_session_five {
            background-color: #45818e;
          }

          &.status_session_four {
            background-color: #45818e;
          }
        }
      }
    }

    .box-info-time-session {
      position: absolute;
      bottom: 140px;
      left: 0;
      width: 100%;
      color: #fff;

      .header-detail-session {
        height: 32px;
        background-color: #1a1a1a;
        border-radius: 8px;
        padding: 12px 12px;
        justify-content: space-between;
      }

      .capacity-session {
        color: #e69138;
      }
    }
  }

  .content-detail-session {
    background-color: $bg_black_default;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    top: -120px;
    margin: 15px 30px;
    padding: 15px 30px;

    .box-header-content-detail-session {
      padding: 15px 0;
      position: relative;
      height: 90px;

      .title {
        font-size: 24px;
        font-weight: 500;
      }

      .action-close {
        position: absolute;
        right: 0;
        top: 10px;

        img {
          width: 36px;
          filter: invert(100%) sepia(92%) saturate(2%) hue-rotate(315deg)
            brightness(107%) contrast(100%);
        }
      }
    }

    .info-user-host {
      padding: 15px 0;

      .box-info-host {
        .image {
          img {
            width: 32px;
            border-radius: 50%;
            object-fit: cover;
          }
        }
      }
    }
  }

  .box-price {
    color: #fff;
    padding-bottom: 30px;

    .number-price {
      font-size: 80px;
    }

    .text-price {
      position: relative;
      top: 20px;
      left: 10px;
    }
  }

  .action-button-session {
    button {
      width: 80%;
      height: 48px;
      color: #fff;
      border-radius: 8px;
    }

    .view-detail {
      margin-bottom: 15px;

      button {
        border-color: #fff;
      }
    }

    .action_livestream {
      button {
        background: no-repeat 100% 100%;
        height: 64px;
        border: 0;
      }

      .disabled-button {
        background-color: #525252;
      }

      .orange-button {
        background-color: #e69138;
      }
    }
  }
}

.modal-detail-session {
  .header-modal-detail-session {
    background-color: #1a1a1a;
    border-bottom: 0;
    display: initial !important;
    color: #fff;
    padding: 10px 15px;

    .action-close-button {
      position: absolute;
      top: 10px;
      right: 10px;

      img {
        cursor: pointer;
        width: 26px;
        filter: invert(100%) sepia(92%) saturate(2%) hue-rotate(315deg)
          brightness(107%) contrast(100%);
      }
    }

    .content-header-modal {
      border-bottom: 1px solid #ddd;
      padding: 20px 0;

      .title-modal-detail-session {
        font-size: 22px;
        font-weight: 500;
      }
    }
  }

  .modal-body {
    background-color: #1a1a1a;
    color: #fff;

    .description-detail-session {
      padding-bottom: 20px;
      border-bottom: 1px solid #ddd;
    }
  }
}

.camelia-web {
  #streamer-audience {
    position: relative;
    width: 100%;
    height: calc($heightVh100 - 120px) !important;
  
    div {
      background-color: #1a1a1a !important;
    }
  
    video {
      object-fit: contain !important;
      height: calc($heightVh100 - 120px) !important;
    }
  }
}

.event-video {
  position: relative;

  .footer-camera-audience {
    bottom: 12px;
    padding: 0 15px;

    .box-camera-audience {
      width: 140px;
      height: 56px;
      border-radius: 6px;
      background-color: #323232;

      img {
        width: 24px;
        height: 24px;
      }

      .box-info-camera-audience {
        height: 28px;
        color: #e69138;
      }

      .amount-image {
        border-bottom: 1px solid #1a1a1a;
      }
    }
  }

  .text_wait_live_stream {
    top: 40%;
    left: 0;
    text-align: center;
  }

  .disable-change-host {
    img {
      filter: invert(50%) sepia(1%) saturate(42%) hue-rotate(353deg)
        brightness(96%) contrast(106%);
    }
  }

  .action-leave-stream {
    width: 24px;
    top: 10px;
    right: 10px;
    img {
      filter: invert(50%) sepia(1%) saturate(42%) hue-rotate(353deg)
        brightness(96%) contrast(106%);
    }
  }
  .logo-leave-stream {
    position: fixed;
    top: 80px;
    left: 50%;
    transform: translate(-50%, -50%);
    img {
      width: 100px;
      filter: invert(50%) sepia(0%) saturate(100%) hue-rotate(20deg)
        brightness(135%) contrast(114%);
    }
  }

  .bg-photo {
    video {
      opacity: 0;
    }
  }
}

.list-video-host {
  position: absolute;
  bottom: 0;
  min-height: 250px;
  background-color: #2c2c2c;
  width: 100%;
  left: 0;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  opacity: 0;
  transition: 0.4s ease;
  transform: translateY(200%);
  visibility: hidden;

  &.show {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    z-index: 99;
  }

  .content-list-video-host {
    position: relative;

    .action-close {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 24px;

      img {
        max-width: 24px;
        filter: invert(100%) sepia(92%) saturate(2%) hue-rotate(315deg)
          brightness(107%) contrast(100%);
      }
    }
  }

  .list-video {
    div {
      background-color: #2c2c2c;
      border-radius: 8px;
    }

    video {
      border-radius: 8px;
    }
  }
}

.camelia-mobile {
  .broadcast-livestream {
    position: absolute;
    bottom: 0;
    top: -60px;
    min-height: 250px;
    background-color: #1A1A1A;
    width: 100%;
    left: 0;
    opacity: 0;
    transition: .4s ease;
    transform: translateY(200%);
    visibility: hidden;
    &.show {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
      z-index: 99;
      height: $heightVh100;
    }
    #streamer-audience {
      height: $heightVh100 !important;
      .agora_video_player {
        height: $heightVh100 !important;
      }
    }
  }
  .event-video {
    .logo-leave-stream {
      top: 22px !important;
    }
    .uid-live-stream {
      font-size: 1px;
      margin-left: 10px;
      color: #444;
    }
    .footer-camera-audience {
      bottom: 10px;
      padding: 0 !important;
      .box-camera-icon {
        width: 100px !important;
        img {
          width: 20px !important;
          height: 20px !important;
        }
      }
    }
  }
}
.camelia-web {
  .broadcast-livestream {
    position: relative;
  }
}


#list-video-playback {
  .box-video {
    max-width: 25%;
    height: 92px;
    object-fit: contain;
  }

  flex-wrap: wrap;
  max-height: 300px;
}
@media (max-width: 480px) {
  #list-video-playback {
    .box-video {
      max-width: 33.3333% !important;
    }
  }
}
@media (max-width: 400px) {
  #list-video-playback {
    .box-video {
      max-width: 50% !important;
    }
  }
}
