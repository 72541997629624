@import "./global.scss";
.content-profile {
  height: calc($heightVh100 - 350px);
  overflow: auto;
  &::-webkit-scrollbar {
    background-color: #1a1a1a;
    width: 8px;
    height: 8px;
    cursor: pointer;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    box-shadow: inset 0 0 6px rgb(0 0 0 / 0%);
    background-color: #1a1a1a;
    cursor: pointer;
  }
}
.list-point-history {
  margin-top: 30px;
  a {
    text-decoration: none;
  }
  .box-item-point-history {
    margin-bottom: 20px;
    .box-image {
      img {
        width: 165px;
        height: 110px;
        object-fit: cover;
        border-radius: 8px;
        background-color: #fff;
      }
    }
    .box-info-session {
      padding-left: 30px;
      .date-status-session {
        .text-date {
          display: flex;
          flex-wrap: wrap;
          color: #707070;
        }
        .status-sesion-point {
          min-width: 56px;
          padding: 3px 10px;
          border-radius: 6px;
          background: #e69138;
          text-align: center;
          color: #fff;
          &.session-end {
            background: #45818e !important;
          }
        }
      }
      .box-title {
        font-size: 16px;
        width: 290px;
      }
      .box-info-image-photo {
        img {
          width: 22px;
          height: 22px;
          object-fit: contain;
          border-radius: 0 !important;
        }
        .box-image {
          img {
            filter: invert(90%) sepia(3%) saturate(100%) hue-rotate(178deg)
              brightness(109%) contrast(100%);
          }
        }
      }
    }
  }
}
.list_setting-tab {
  ul {
    margin: 20px;
    padding: 0;
    li {
      list-style: none;
      padding: 0 10px;
      height: 48px;
      border-bottom: 1px solid #707070;
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      a {
        text-decoration: none;
      }
    }
    .icon-item {
      position: absolute;
      top: 0;
      right: 0;
      height: 48px;
      display: flex;
      align-items: center;
      .btn-on-off-notification {
        height: 32px;
        width: 90px;
        padding: 0;
        background-color: #707070;
        border-radius: 8px;
        color: #fff;
        font-weight: 300;
        font-size: 12px;
        &.on-notification {
          background-color: #e69138;
        }
      }
      img {
        width: 20px;
        filter: invert(48%) sepia(0%) saturate(17%) hue-rotate(136deg)
          brightness(86%) contrast(91%);
      }
    }
  }
}
