@import './src/assets/scss/variables';

.header-container-profile {
  cursor: pointer;
  height: 230px;

  .avatar {
    position: relative;
    z-index: 10;
    margin: auto;
  }

  .card-profile {
    height: 230px;
  }

  .info {
    padding: 0 30px;
    height: 190px;
    .avatar {
      width: 90px;
      height: 90px;
      object-fit: cover;
      border-radius: 50%;
    }
    .action-add-point {
      a {
        border: 1px solid #fff;
        padding: 3px 15px;
        border-radius: 10px;
        text-decoration: none;
        color: #fff;
      }
    }
  }
}

.video-background {
  object-fit: cover;
  width: 100%;
  max-width: 768px;
  height: 230px;
  position: absolute;
  left: auto;
  background: transparent !important;
}

button {
  background-color: transparent;
  color: white;
  width: 96px;
  height: 22px;
  border-width: 2px;
  border-color: white;
  border-radius: 11px !important;
}

.nav-item {
  background-color: $bg_black_secondary;
  border-radius: 10px 10px 0 0;
  height: 40px;
  color: white;

  .active {
    background-color: $bg_black_default;
  }

  a {
    font-family: 'Yu Gothic', sans-serif;
    color: white;
    font-size: 12px;
    font-weight: $fontsize_bold;
  }
}

.nav-link {
  color: white !important;
  background-color: $bg_black_secondary !important;
  z-index: 10;
  height: 40px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.nav-link.active {
  background-color: $bg_black_default !important;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 0px !important;
  --bs-nav-tabs-border-color: $bg_black_default !important;
  --bs-nav-tabs-border-radius: 10px !important;
  --bs-nav-tabs-link-hover-border-color: $bg_black_default $bg_black_default $bg_black_default !important;
  --bs-nav-tabs-link-active-color: white !important;
  --bs-nav-tabs-link-active-bg: $bg_black_default !important;
  --bs-nav-tabs-link-active-border-color: $bg_black_default $bg_black_default $bg_black_default;
  z-index: 20;
}

.icon-pen {
  width: 16px;
  height: 16px;
}

.icon-pen-float {
  position: absolute;
  top: 5vh;
  right: 5vw;
  z-index: 11;
}