@import "./global.scss";

.page-album {
    height: calc($heightVh100 - 120px);
    .header-content-album {
        height: 66px;
    }
    .title-page {
        font-size: 30px;
    }
    .description-page {
        font-size: 14px;
    }
    .list-album {
        padding: 30px 0;
        height: calc($heightVh100 - 186px);
        overflow: auto;
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
            cursor: pointer;
          }
        &::-webkit-scrollbar-thumb {
            border-radius: 0;
        }
        .box-album {
            margin-bottom: 20px;
            cursor: pointer;
            .image {
                img {
                    width: 100%;
                    height: 250px;
                    object-fit: cover;
                    border-radius: 15px;
                }
            }
            .title {
                height: 60px;
                bottom: 0;
                background-color: #1a1a1a;
                width: 100%;
                border-radius: 10px;
                box-shadow: 0 2px 4px #E6913833;
                display: flex;
                align-items: center;
                padding: 10px;
                font-size: 16px;
            }
        }
    }
    .list-image-session-modal {
        .preview-image {
            height: calc($heightVh100 - 120px);
            .image {
                overflow: hidden;
                height: calc($heightVh100 - 180px);
                img {
                    width: 100%;
                    height: calc($heightVh100 - 180px);
                    object-fit: contain;
                }
            }
            .action-zoom-image {
                bottom: 30px;
                width: 100%;
                display: flex;
                justify-content: center;
                button {
                    width: 40px;
                    height: 40px;
                    background-color: #fff;
                    border: 0;
                    border-radius: 0 !important;
                    border-right: 1px solid #ddd;
                }
                img {
                    width: 22px  !important;
                    height: 22px !important;
                }
            }
            .lb-container {
                background-color: transparent !important;
            }
        }
    }
}
@media (max-width: 480px) {
    .page-album {
        .list-album {
            padding: 20px 0 !important;
            .box-album {
                .image {
                    overflow: hidden;
                    img {
                        height: 190px;
                    }
                }
            }
        }
    }
    // .lb-container {
    //     height: calc($heightVh100 - 200px) !important;
    // }
}