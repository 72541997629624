@import "./global.scss";
.page-notification {
    height: calc($heightVh100 - 120px);
    .list-notification {
        overflow: auto;
        height: calc($heightVh100 - 236px);
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        margin-top: 30px;
        &::-webkit-scrollbar {
            background-color: #f5f5f5;
            width: 0;
            height: 0;
            cursor: pointer;
          }
        .box-notification {
            height: 75px;
            border-bottom: 1px solid #545454;
            cursor: pointer;
            user-select: none;
            .box-image {
                height: 75px;
                width: 70px;
                .image {
                    width: 60px;
                    height: 60px;
                    background: #747474;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        max-width: 40px;
                        height: 34px;
                        object-fit: contain;
                    }
                }
            }
            .info-notification {
                width: calc(100% - 70px);
                .box-title {
                    width: calc(100% - 20px);
                    .title {
                        font-size: 14px;
                        padding: 10px 0;
                    }
                    .time {
                        position: absolute;
                        font-size: 12px;
                        color: #545454;
                        bottom: 5px;
                    }
                }
                
                .icon {
                    right: 0;
                    height: 75px;
                    display: flex;
                    align-items: center;
                    img {
                        width: 20px;
                        filter: invert(47%) sepia(8%) saturate(9%) hue-rotate(342deg) brightness(93%) contrast(89%);
                    }
                }
            }
        }
    }
}

.detail-notification {
    .header-notification {
        height: 60px;
        border-bottom: 1px solid #545454;
        .back {
            width: 30px;
            img {
                width: 22px;
                filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(71deg) brightness(106%) contrast(101%);
            }
        }
        .title {
            width: calc(100% - 22px);
            font-size: 16px;
        }
    }
    .box-content-notfication {
        padding: 0 30px;
        .header-title-notification {
            border-bottom: 1px solid #ddd;
            .title {
                padding: 10px 0;
                font-size: 16px;
            }
            .date {
                padding-bottom: 10px;
                font-size: 14px;
            }
        }
        .content {
            margin-top: 20px;
            max-height: calc($heightVh100 - 290px);
            overflow: auto;
            &::-webkit-scrollbar {
                background-color: #f5f5f5;
                width: 0;
                height: 0;
                cursor: pointer;
              }
        }
    }
}

@media (max-width: 480px) {
    .page-notification {
        .list-notification {
            margin-top: 20px;
            height: calc($heightVh100 - 216px) !important;
            .box-image {
                .image {
                    width: 50px !important;
                    height: 50px !important;
                    img {
                        max-width: 30px !important;
                        height: 25px;
                        object-fit: contain !important;
                    }
                }
            }
            .info-notification {
                .box-title {
                    .title {
                        font-size: 12px !important;
                    }
                    .time {
                        font-size: 10px !important;
                    }
                }
            }
        }
    }
    .detail-notification {
        .header-notification {
            padding: 0 15px;
            .title {
                font-size: 14px !important;
            }
        }
        .box-content-notfication {
            padding: 0 15px !important;
            .header-title-notification {
                .title {
                    font-size: 14px !important;
                }
                .date {
                    font-size: 12px !important;
                }
            }
            .content {
               font-size: 12px !important;
            }
        }
    }
}