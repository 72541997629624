@import './src/assets/scss/variables';

.point-detail-session {
  padding: 30px;
}

.my-point-container {
  height: 3rem !important;
  border: 1px solid white;
  border-radius: 0.5rem;
  background-color: white;
  margin-bottom: 3rem !important;

  .title {
    color: black;
  }

  .my-point {
    color: black;
  }
}

.point-container {
  height: 4.5rem;
  border: 1px solid white;
  border-radius: 0.5rem;
  color: white;
  margin-bottom: 1rem;
  padding: 0 1rem;
  display: flex;
  flex-flow: row wrap;

  * {
    flex: 1 auto;
    margin: auto 0;
    text-align: center;
    color: white;
    font-size: 12pt;
    display: grid;
    align-content: space-between;
    justify-content: center;
  }

  .point-name {
    min-width: 7.5rem;
  }

  .point-price {
    flex: 2 auto;
    margin: auto 2rem;

    .price {
      text-align: center;
      font-size: 12pt;
      color: black;
      width: 5rem;
      background-color: #fff;
      border-radius: 12pt;
      line-height: 13pt;
    }
  }

  .buy-point {
    flex: 1 auto;
    height: 40px;
    max-width: 8rem;


    span {
      top: -2rem;
      position: relative;
    }

    img {
      height: 2.5rem;
      max-width: 5.5rem;
    }
  }
}

.box-title-detail-session {
  padding: 15px 0;
  position: relative;
  height: 90px;

  .title {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
  }

  .action-close {
    position: absolute;
    right: 0;
    top: 10px;

    img {
      width: 36px;
      filter: invert(100%) sepia(92%) saturate(2%) hue-rotate(315deg) brightness(107%) contrast(100%);
    }
  }
}

.modal-buy-point {
  .text-white.text-center.mt-5.mb-0 {
    margin-top: -6rem !important;
    font-size: 20px;
  }

  .point-container {
    padding: 0 0.25rem;

    .point-name {
      min-width: 2.5rem;
    }

    .point-price {
      flex: 1 auto;
      margin: auto 0;

      .price {
        width: 5rem;
      }
    }
  }
}

@media (max-width: 420px) {
  .point-container {

    .point-name {
      min-width: 2.5rem;
    }

    .point-price {
      flex: 1 auto;
      margin: auto 0;

      .price {
        width: 5rem;
      }
    }
  }
}

@media (max-width: 320px) {
  .point-container {
    padding: 0 0.25rem;
  }
}

