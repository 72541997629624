@media (max-width: 1000px) {
  .header {
    padding: 0 15px;
  }
}

@media (max-width: 640px) {
  .hidden-app {
    display: none;
  }
  .content-session-list {
    .box-session-home {
      width: 100% !important;
    }
  }
  .detail-session {
    .image-session-detail {
      .status-session {
        bottom: 100px;
        .content-text {
          width: 85% !important;
        }
      }
      img {
        height: 220px;
      }
      .box-info-time-session {
        bottom: 60px;
        .header-detail-session {
          width: 85% !important;
        }
      }
    }
    .content-detail-session {
      top: -50px;
      margin: 0;
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;
      .box-header-content-detail-session {
        height: auto;
        padding: 0;
        .title {
          font-size: 18px;
        }
        .action-close {
          top: 0;
          img {
            width: 22px;
          }
        }
      }
    }
    .box-price {
      padding-bottom: 20px;
      .number-price {
        font-size: 60px;
      }
    }
    .action-button-session {
      button {
        width: 100%;
      }
      .action_livestream {
        button {
          height: 60px;
        }
      }
    }
  }
  .modal-confirm {
    .modal-dialog {
      width: 320px !important;
    }
  }
  .page-edit-profile {
    .content-page-edit-profile {
      padding: 80px 90px !important;
    }
  }
}

@media (max-width: 480px) {
  .form-login {
    .modal-content {
      height: calc($heightVh100 - 100px) !important;
      background-color: #1a1a1a;
    }
  }
  .modal-confirm {
    .modal-dialog {
      width: 300px !important;
    }
  }
  .content-page-app {
    padding: 15px !important;
  }
  .wrapper {
    background-color: $bg_black_default;
  }
  .pad .modal-full-page {
    height: calc($heightVh100 - 120px) !important;
  }
  .body-modal-form-register {
    padding: 0;
  }
  .action-login {
    padding: 20px 0 20px 0;
  }
  .box-next-session {
    .image-box {
      img {
        height: 220px;
        border-radius: 18px;
      }
    }
  }
  .feature-session {
    .item-session {
      .images {
        img {
          height: 220px;
          border-radius: 18px;
        }
      }
    }
  }
  .item-session {
    .images {
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;
      img {
        height: 120px !important;
        border-top-left-radius: 18px;
        border-top-right-radius: 18px;
      }
    }
    .info-session {
      width: 94%;
    }
  }
  .action-list-session {
    button {
      width: 70% !important;
    }
  }
  .box-list-session-home {
    .content-session-list {
      .box-session-home {
        .box-image {
          img {
            height: 130px;
          }
        }
      }
    }
  }
  .box-next-session {
    .countdown-box {
      font-size: 18px;
    }
  }
  .title-home {
    font-size: 26px;
  }

  .header-container-profile {
    height: 200px !important;
    .info {
      padding: 0 15px;
      height: 160px;
      .info-user-profile {
        div {
          font-size: 11px;
        }
      }
    }
    .video-background {
      height: 200px !important;
    }
    .card-profile {
      height: 200px !important;
    }
  }
  .list-point-history {
    .box-item-point-history {
      padding: 0 20px;
      .box-image {
        img {
          max-width: 125px;
          height: 90px;
        }
      }
    }
  }
  .content-profile {
    height: calc($heightVh100 - 320px) !important;
  }
  .page-edit-profile {
    .content-page-edit-profile {
      padding: 80px 15px !important;
    }
  }
  .event-video {
    .footer-camera-audience {
      bottom: 30px;
      .box-camera-audience {
        width: 100px !important;
        height: 45px !important;
        .box-info-camera-audience {
          height: 22.5px !important;
          .text {
            width: 30px;
          }
          .amount {
            overflow: hidden;
            width: calc(100% - 30px);
          }
          span {
            font-size: 10px !important;
          }
        }
      }
    }
  }
}
@media (max-width: 400px) {
  .list-point-history {
    .box-item-point-history {
      padding: 0 15px !important;
      .date-status-session {
        * {
          font-size: 10px !important;
        }
      }
      .box-info-session {
        padding-left: 15px;
        .box-title {
          font-size: 14px;
          width: auto !important;
          max-width: 290px;
        }
      }
    }
  }
  .page-images-session {
    .list-image-session {
      .box-image {
        width: 33.333%;
        height: 120px !important;
        img {
          height: 120px !important;
        }
      }
    }
  }
}
@media (max-width: 375px) {
  .body-modal-form-register {
    padding: 0;
  }
  .action-login {
    padding: 20px 0 20px 0;
  }
  .modal {
    overflow: auto !important;
  }
  .form-login {
    .modal-content {
      height: calc($heightVh100 - 200px) !important;
      background-color: #1a1a1a;
    }
    // .modal-body {
    //     max-height: 350px !important;
    //     min-height: 300px !important;
    //     overflow: auto;
    // }
    .body-modal-forgot-password {
      min-height: 250px !important;
    }
  }
}
