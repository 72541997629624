$bg_black_default: #1a1a1a;
.logo-login {
  img {
    max-width: 120px;
  }
}

.action-login {
  .button-login {
    width: 60%;
    height: 48px;
    background-color: #e69138 !important;
    border: 1px solid #e69138 !important;
    color: #fff;
    &:hover {
      color: #fff !important;
    }
  }

  .disable-button {
    background-color: #707070 !important;
    border: 1px solid #707070 !important;
  }
}

.action-register {
  margin: 20px auto auto;
  border-top: 2px solid #333333;
  display: flex;
  justify-content: center;

  .link-action-register {
    width: 60%;

    .link-action {
      color: #e69138;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  a {
    color: #e69138;

    &:hover {
      color: #e69138;
    }
  }
}

.header-login {
  position: relative;

  .action-close-button {
    position: absolute;
    top: 10px;
    right: 10px;

    img {
      cursor: pointer;
      width: 26px;
      filter: invert(18%) sepia(33%) saturate(0%) hue-rotate(227deg)
        brightness(98%) contrast(98%);
    }
  }
}

.form-login {
  padding-left: 0 !important;

  .modal-header {
    background-color: $bg_black_default;
    border-bottom: 0;
    display: initial !important;
  }

  .modal-body {
    // min-height: 500px;
    background-color: $bg_black_default;
  }

  .box-input {
    label {
      color: #333333;
    }

    input {
      background-color: $bg_black_default;
      border: 1px solid #333333;
      color: #ffffff;
      height: 40px;

      &:focus {
        outline: none !important;
        box-shadow: none !important;
      }

      &.is-invalid {
        border: 1px solid #dc3545;
      }
    }
  }
}

.input-password {
  input {
    border-right: 0 !important;
  }

  .input-group-text {
    background-color: $bg_black_default;
    border: 1px solid #333333;
    border-left: 0 !important;
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;

    &.is-invalid {
      border: 1px solid #dc3545;
    }
  }
}

.success-input {
  input {
    border: 1px solid #e69138 !important;
    background-color: $bg_black_default;
  }

  .border-end-0 {
    border-right: 0 !important;
  }

  .input-group-text {
    border: 1px solid #e69138 !important;
    border-left: 0 !important;
  }
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.form-confirm-email {
  position: fixed;
  bottom: 0;
  min-height: 250px;
  background-color: #2c2c2c;
  width: 100%;
  max-width: 500px;
  left: 50%;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  opacity: 0;
  transition: 0.4s ease;
  transform: translate(-50%, 200%);
  visibility: hidden;
  &.show {
    opacity: 1;
    visibility: visible;
    transform: translate(-50%, -60px);
    z-index: 1999;
  }

  .content-confirm-email {
    position: relative;

    .action-close {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 24px;

      img {
        max-width: 24px;
        filter: invert(100%) sepia(92%) saturate(2%) hue-rotate(315deg)
          brightness(107%) contrast(100%);
      }
    }

    .title-confirm-email {
      font-size: 22px;
      color: #fff;
      padding-top: 40px;
      text-align: center;
    }

    .content {
      text-align: center;
      padding: 30px 50px;
      color: #fff;
    }

    .button-confirm {
      text-align: center;

      button {
        width: 70%;
        background-color: #e69138;
        height: 48px;
        color: #fff;
      }
    }
  }
}

.body-modal-form-register {
  padding: 30px;
}

.action-login {
  padding: 20px 0 50px 0;
}
.body-modal-forgot-password {
  min-height: 550px;
}

@media (max-width: 480px) {
  .form-confirm-email {
    &.show {
      width: 100%;
    }
  }
}
