@import "./global.scss";
.page-detail-profile-config {
    .header-detail-profile-config {
        height: 60px;
        border-bottom: 1px solid #747474;
        .back {
            min-width: 30px;
            img {
                width: 22px;
                filter: invert(99%) sepia(29%) saturate(44%) hue-rotate(133deg) brightness(116%) contrast(100%);
            }
        }
        .title {
            width: calc(100% - 30px);
            font-size: 16px;
        }
    }
    .content-detail-profile-config {
        height: calc($heightVh100 - 205px);
        padding: 30px 0;
        color: #fff;
        overflow: auto;
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
            cursor: pointer;
          }
        .text-back-home {
            a {
                color: #E69138;
            }
        }
    }
}