.page-edit-profile {
    .header-page-edit-profile {
        height: 60px;
        border-bottom: 1px solid #747474;
        .back {
            width: 30px;
            img {
                width: 22px;
                filter: invert(100%) sepia(1%) saturate(0%) hue-rotate(154deg) brightness(107%) contrast(100%);
            }
        }
        .title {
            font-size: 16px;
        }
        .submit-edit {
            color: #E69138;
            font-size: 16px;
        }
    }
    .content-page-edit-profile {
        padding: 80px 120px;
        .avatar {
            label {
                width: 120px;
                height: 120px;
                border-radius: 50%;
                img {
                    width: 120px;
                    height: 120px;
                    object-fit: cover;
                    border-radius: 50%;
                }
            }
        }
        .box-input-edit-profile {
            label {
                input{
                    height: 40px;
                    padding-right: 70px;
                    border: 1px solid #707070;
                    background-color: #1A1A1A;
                    color: #fff;
                    &:focus {
                        outline: unset !important;
                        box-shadow: unset !important;
                        border-color: #707070 !important;
                    }
                    &.box-success-input {
                        border-color: #E69138 !important;
                        &:focus {
                            border-color: #E69138 !important;
                            border: 1px solid #E69138 !important;
                        }
                    }
                }
                .submit-input-edit {
                    width: 62px;
                    height: 30px;
                    position: absolute;
                    right: 5px;
                    background-color: #707070;
                    color: #fff;
                    border: 1px solid #707070;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &.successInput {
                        background-color: #E69138;
                        border-color: #E69138;
                    }
                }
            }
        }
    }
}
.box-success-input {
    border-color: #E69138 !important;
    &:focus {
        border-color: #E69138 !important;
        border: 1px solid #E69138 !important;
    }
}